<template>
  <div class="card card-custom mb-10">
    <v-row class="my-5 mx-1">
  <v-col cols="12" sm="6" md="3">
    <div class="card-toolbar" style="display: grid;">
      <label for>
        <b>Product Toppings</b>
      </label>
      <b-form-file
        v-model="productToppingFile"
        placeholder="Choose a file to upload"
        drop-placeholder="Drop file here..."
        class="mb-1"
      ></b-form-file>
      <button
        v-on:click="saveToppings"
        v-if="productToppingFile != null"
        class="btn btn-success"
      >
        <i class="flaticon2-add-1"></i>Upload Product Toppings
      </button>
    </div>
  </v-col>

  <v-col cols="12" sm="6" md="3">
    <div class="card-toolbar" style="display: grid;">
      <label for>
        <b>Product Properties</b>
      </label>
      <b-form-file
        v-model="productPropertyFile"
        placeholder="Choose a file to upload"
        drop-placeholder="Drop file here..."
        class="mb-2"
      ></b-form-file>
      <button
        v-on:click="saveProperties"
        v-if="productPropertyFile != null"
        class="btn btn-success"
      >
        <i class="flaticon2-add-1"></i>Upload Product Properties
      </button>
    </div>
  </v-col>

  <v-col cols="12" sm="6" md="3">
    <div class="card-toolbar" style="display: grid;">
      <label for>
        <b>Products</b>
      </label>
      <b-form-file
        v-model="productFile"
        placeholder="Choose a file to upload"
        drop-placeholder="Drop file here..."
        class="mb-2"
      ></b-form-file>
      <button
        v-on:click="save"
        v-if="productFile != null"
        class="btn btn-success"
      >
        <i class="flaticon2-add-1"></i>Upload Products
      </button>
    </div>
  </v-col>

  <v-col cols="12" sm="6" md="3" class="pt-14">
    <v-btn small class="bg-primary" dark @click="addNew">
      <v-icon left>mdi-plus</v-icon>Add New
    </v-btn>
    <v-btn small class="mx-1 text-light mr-2" style="background-color: red;" @click="deleteAll">
      <v-icon left>mdi-delete</v-icon>Delete All Products
    </v-btn>
  </v-col>

    </v-row>

    <div class="card-body pt-1 pb-3">
      <div class="row mt-2 mb-4"></div>
      <div class="form-group row">
        <div class="col-lg-8"></div>
        <div class="col-lg-4">
          <v-text-field
            type="text"
            v-model="search"
            v-on:keyup.enter="get('')"
            outlined
            placeholder="Search in Products"
          />
        </div>
        <!-- <b-form-select
          class="col-lg-1"
          v-model="limit"
          v-on:change="get()"
          :options="$store.state.profile.rows"
        ></b-form-select>-->
      </div>
      <br />
      <!-- <div class="d-flex justify-content-between border-top pt-5">
        <div class="mr-2">
          <button
            class="btn btn-light-success font-weight-bold text-uppercase px-3 py-2"
            data-wizard-type="action-prev"
            :disabled="prev == null"
            v-on:click="get(prev)"
          >
            << Previous
          </button>
        </div>
        <div>
          <button
            class="btn btn-light-danger font-weight-bold text-uppercase px-3 py-2"
            data-wizard-type="action-next"
            :disabled="next == null"
            v-on:click="get(next)"
          >
            Next >>
          </button>
        </div>
      </div>-->
      <br />
      <div class="tab-content">
        <v-data-table
          :headers="headers"
          :items="products"
          :items-per-page="limit"
          :page.sync="currentPage"
          :server-items-length="totalItems"
          @update:page="get"
          @update:items-per-page="get"
          :search="search"
        >
          <template v-slot:item.logo="{ item }">
            <v-avatar size="40px" v-if="item.logo">
              <img :src="item.logo" alt="Product Image" />
            </v-avatar>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon medium class="mr-2" @click="edit(item.id)">mdi-pencil</v-icon>
            <v-icon medium @click="deleteItem(item.id)">mdi-delete</v-icon>
          </template>
        </v-data-table>

        <!--end::Table-->
        <!-- Pagination -->
        <v-pagination v-model="currentPage" :length="totalPages" :total-visible="7" @input="get"></v-pagination>
      </div>
    </div>
    <b-modal id="product-modal" ref="product-modal" size="lg" title="Add Product" hide-footer>
      <MenuProductForm :id="currentId" @onClickCancel="hideModal" @success="formSuccess"></MenuProductForm>
    </b-modal>
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import MenuProductForm from "@/view/pages/menu/form-components/MenuProductForm";

export default {
  name: "Products",
  components: {
    MenuProductForm
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Products" }]);
    this.get();
  },
  data() {
    return {
      dialog: false,
      singleSelect: false,
      selected: [],
      products: [],
      currentId: "",
      next: null,
      prev: null,
      productFile: null,
      productPropertyFile: null,
      productToppingFile: null,

      headers: [
        { text: "ID", value: "id", width: "50px" },
        { text: "Image", value: "logo", width: "80px" },
        { text: "Product Code", value: "code", width: "120px" },
        { text: "Name", value: "name", width: "120px" },
        { text: "Price", value: "price", width: "80px" },
        { text: "Category", value: "category_name", width: "120px" },
        { text: "Hide Item", value: "hide_item", width: "100px" },
        { text: "Vegetarian", value: "vegetarian", width: "100px" },
        { text: "Vegan", value: "vegan", width: "100px" },
        { text: "Gluten Free", value: "gluten_free", width: "100px" },
        { text: "Plant Based", value: "plant_based", width: "100px" },
        { text: "Spicyness", value: "spice", width: "100px" },
        { text: "Actions", value: "actions", width: "100px" }
      ],
      currentPage: 1,
      limit: 10,
      totalItems: 0,
      search: "",
      totalPages: 0
    };
  },
  methods: {
    save() {
      let formData = new FormData();
      if (this.productFile != null) {
        formData.append("file", this.productFile);
        ApiService.fileUpload("business/import/products", formData)
          .then(({ data }) => {
            if (data == 1) {
              Swal.fire("Success", "File Uploaded Successfully", "success");
              this.get();
            } else {
              Swal.fire("Error", data, "error");
            }
          })
          .catch(({ response }) => {
            console.log(response);
          });
      }
    },
    saveToppings() {
      let formData = new FormData();
      if (this.productToppingFile != null) {
        formData.append("file", this.productToppingFile);
        ApiService.fileUpload("business/import/product-toppings", formData)
          .then(({ data }) => {
            if (data == 1) {
              Swal.fire("Success", "File Uploaded Successfully", "success");
              this.get();
            } else {
              Swal.fire("Error", data, "error");
            }
          })
          .catch(({ response }) => {
            console.log(response);
          });
      }
    },
    saveProperties() {
      let formData = new FormData();
      if (this.productPropertyFile != null) {
        formData.append("file", this.productPropertyFile);
        ApiService.fileUpload("business/import/product-properties", formData)
          .then(({ data }) => {
            if (data == 1) {
              Swal.fire("Success", "File Uploaded Successfully", "success");
              this.get();
            } else {
              Swal.fire("Error", data, "error");
            }
          })
          .catch(({ response }) => {
            console.log(response);
          });
      }
    },
    get() {
      ApiService.get(
        `business/menu/products?page=${this.currentPage}&limit=${this.limit}&search=${this.search}`
      )
        .then(({ data }) => {
          this.products = data.data;
          this.totalItems = data.meta.total;
          this.totalPages = data.meta.last_page;
        })
        .catch(error => {
          console.log(error);
        });
    },
    edit(id) {
      this.currentId = id;
      this.$refs["product-modal"].show();
    },
    hideModal() {
      this.$refs["product-modal"].hide();
    },
    addNew() {
      this.currentId = "";
      this.$refs["product-modal"].show();
    },
    deleteItem(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          ApiService.delete("business/menu/products/" + id).then(({ data }) => {
            this.get();
            Swal.fire("Deleted!", "Your product has been deleted.", "success");
          });
        }
      });
    },
    deleteAll() {
      Swal.fire({
        title: "This will delete all products",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          ApiService.post("business/menu/products/delete-all", []).then(
            ({ data }) => {
              this.get();
              Swal.fire(
                "Deleted!",
                "Your products has been deleted.",
                "success"
              );
            }
          );
        }
      });
    },
    formSuccess() {
      this.hideModal();
      this.get();
      Swal.fire({
        title: "",
        text: "The product has been successfully added!",
        icon: "success"
      });
    }
  }
};
</script>